import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';

const About = ({ data }) => {
  var pageTitle = 'About Us';

  return (
    <Layout
      slug="about"
      title={pageTitle}
      description="Learn more about the faces behind Very Important Pets as well as our mission and vision for the company."
    >
      <h1>{pageTitle}</h1>
      <GatsbyImage
        image={getImage(data.family)}
        alt="family"
        className="m-auto mb-4 rounded-xl"
        style={{ maxWidth: 800 }}
      />
      <p className="text-center text-lg my-8">
        Our mission is to provide a safe and fun environment for your pet while
        leaving you feeling confident about the care your pet is receiving. We
        understand that every pet is unique and we take pride in being able to
        cater to your individual pet’s needs.
      </p>
      <p className="text-center text-lg my-8">
        Our vision is to create a new standard for the level of care pets
        receive.
      </p>

      <p>
        We are a small family owned business, located near Halkirk, established
        as a grooming salon in 1992. Soon after we bought our family farm, we
        began receiving requests to pet sit. Laura's first dog had lost her leg
        and shoulder blade due to cancer at a young age. By this time she was 15
        year old and required special care due to both her missing limb and
        senior years. This helped us understand the difficulty in leaving a
        beloved family member while away. We wanted to be able to provide a
        great experience for pets as well as peace of mind for their owners.
      </p>

      <h2 id="boarding-team" className="text-center mt-20">
        Our Boarding Team
      </h2>
      <section className="flex flex-col lg:flex-row items-center mb-20">
        <div className="lg:w-1/2">
          <GatsbyImage
            image={getImage(data.laurakennel)}
            alt="Laura"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
        </div>
        <div className="lg:w-1/2">
          <h3>Laura - Owner and Manager</h3>
          <p>
            Laura has loved being in the pet care industry for over 25 years.
            After graduating from an animal sciences program, Laura began her
            career as a veterinary assistant. Then, realizing her passion for
            animal welfare she started her own business as a pet groomer. After
            moving to an acreage outside of Halkirk she soon began to receive
            requests to pet sit her grooming clients. In 2009 she finally
            decided to take a leap of faith and start her own boarding kennel.
            Her goal when opening the kennel, and still is, to be able to
            provide a great experience for pets as well as peace of mind for
            their owners.
          </p>
          <p>
            Laura has also taken a pet nutrition course and is certified in pet
            first aid as well as numerous other courses.
          </p>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row items-center mb-20">
        <div className="lg:w-1/2">
          <GatsbyImage
            image={getImage(data.tandel)}
            alt="Tiana and Elsie"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
        </div>
        <div className="lg:w-1/2">
          <h3>Tiana - Assistant Manager</h3>
          <p>
            Tiana grew up in the industry and knew from a very young age that
            she would follow in her mother’s footsteps. From playing
            veterinarian with our own pets to learning to groom dogs in her
            early teens, animals have always been her passion. She is a
            certified Veterinary Technical Assistant. In addition she has taken
            a pet nutrition course, pet first aid and is fear free certified,
            specializing in alleviating fear anxiety and stress for the pets in
            our care. Tiana has recently brought her first pet, Elsie, into her
            home and is keeping us all on our toes!
          </p>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row items-center mb-20">
        <div className="lg:w-1/2">
          <GatsbyImage
            image={getImage(data.tovah)}
            alt="Tovah"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
        </div>
        <div className="lg:w-1/2">
          <h3>Tovah - Kennel Attendant</h3>
          <p>
            Tovah joined us in the spring of 2023 and has been an amazing asset
            to our team. She recently received her pet first aid certificate and
            has enjoyed learning more about the science behind dog behaviour.
            Coming from a background in house cleaning, Tovah excels in ensuring
            our facility is kept in tip top shape!
          </p>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row items-center mb-20">
        <div className="lg:w-1/2">
          <GatsbyImage
            image={getImage(data.cam)}
            alt="Cam"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
        </div>
        <div className="lg:w-1/2">
          <h3>Cam - Kennel Attendant</h3>
          <p>
            Cam has been working for us this past year as a kennel attendant. He
            is very hardworking, reliable and does a great job with whatever we
            need him to do. We love this picture as it shows his caring nature
            and love of all animals.
          </p>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row items-center mb-20">
        <div className="lg:w-1/2">
          <GatsbyImage
            image={getImage(data.jkk)}
            alt="Jacey, Kiara and Kassie"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
        </div>
        <div className="lg:w-1/2">
          <h3>Part Time Kennel Attendants</h3>
          <p>
            Jacey is going to university right now pursing her masters. When she
            is home, she is always helping out with both grooming and the
            boarding kennel. She started working as a grooming apprentice to
            Laura at a young age and has many years of experience working with
            animals. She recently adopted her first dog, a border collie cross,
            named Bear!
          </p>
          <p>
            Kiara is in her last year of high school, but still makes time to
            help out when she is able! She has always loved working with animals
            and you can often find her in the kennel reading a book or watching
            a movie with the animals.
          </p>
          <p>
            Kassie just recently started working at the kennel, but has always
            had a love of animals. She is the jack of all trades and is always
            eager to help with all aspects of the business. She can often be
            found helping out at the grooming shop or just hanging out with the
            animals. Her favourite game to play is fetch!
          </p>
        </div>
      </section>

      <h2 id="grooming-team" className="text-center">
        Our Grooming Team
      </h2>
      <div className="flex flex-wrap justify-around items-start">
        <div className="flex flex-col items-center w-full md:w-2/5 mb-4">
          <GatsbyImage
            image={getImage(data.laura)}
            alt="Laura"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
          <p>
            Laura has been grooming pets for almost 30 years. She first started
            grooming while working at a veterinary clinic and soon after started
            Very Important Pets offering grooming for both dogs and cats. She
            has enjoyed continuing her education through various seminars and
            courses while learning about new styles and techniques as they
            change throughout the years. Laura is also pet first aid certified.
          </p>
        </div>
        <div className="flex flex-col items-center w-full md:w-2/5 mb-4">
          <GatsbyImage
            image={getImage(data.tiana)}
            alt="Tiana"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
          <p>
            Tiana started her grooming career in her early teens as an
            apprentice to her mom, Laura. After graduating from highschool she
            started working full time at Very Important Pets. Tiana has been
            working as a groomer for almost 10 years now and has continued to
            further her education in the pet care industry. In 2018, she became
            an internationally certified cat groomer and she plans to go back to
            school to gain more certifications. Tiana is also pet first aid
            certified.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    family: file(relativePath: { eq: "family.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    laura: file(relativePath: { eq: "laura_portrait.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    tiana: file(relativePath: { eq: "tiana_portrait.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    tandel: file(relativePath: { eq: "elsie_tiana_pumpkin.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    carla: file(relativePath: { eq: "carla.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    jkk: file(relativePath: { eq: "jacey_kiara_kassie.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    laurakennel: file(relativePath: { eq: "laura_oliver.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    tovah: file(relativePath: { eq: "tovah.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: WEST }
        )
      }
    }
    vanessagroom: file(relativePath: { eq: "vanandvi.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    cam: file(relativePath: { eq: "cam_cow.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`;

export default About;
